import React, { useMemo, useState } from 'react';
import Heading from '../components/global/Heading';
import Typography from '../components/global/Typography';
import Score from '../components/Score';
import Icon from '../components/global/Icon';
import { Quote } from '../app/helpers/icons';
import StyledReviewCard from './ReviewCard.style';
import { DateTime } from 'luxon';
import ReadMoreContainer from './ReadMoreContainer';

const ReviewCard = ({ review: _review, ...p }) => {
  const [open, setOpen] = useState(false);
  const review = useMemo(
    () => ({
      client: _review.client.name,
      publish_date: DateTime.fromMillis(_review.publish_date * 1000),
      score:
        2 *
        Number(_review.questions?.find((i) => i.type === 'final_score')?.value),
      quote: _review.questions?.find((i) => i.type === 'main_open')?.value,
      recommends: _review.recommends,
    }),
    [_review]
  );
  return (
    <StyledReviewCard {...p}>
      <div className="header">
        <Heading className="client" variant="heading3" tag="h4">
          {review.client}
        </Heading>
        <Typography variant="controlStrong" color="bahamaBlue" className="date">
          {review.publish_date?.toLocaleString({
            year: 'numeric',
            month: 'numeric',
            day: '2-digit',
          })}
        </Typography>
      </div>
      <div className="score">
        <Score score={review.score} height={18} />
      </div>
      <div className="quote">
        <Icon className="quote-icon" {...Quote} />
        <ReadMoreContainer
          animateHeight
          open={open}
          closedHeight={150}
          bottomSpacing={40}
          button={
            <a
              onClick={() => {
                setOpen(!open);
              }}
              role="button"
            >
              {open ? 'Lees minder' : 'Lees meer'}
            </a>
          }
        >
          {review.quote}
          {!!review.recommends && (
            <div className="recommends">
              Zou u dit bedrijf aanbevelen?&ensp;{review.recommends}
            </div>
          )}
        </ReadMoreContainer>
      </div>
    </StyledReviewCard>
  );
};
export default ReviewCard;
