import { feedbackPage, feedbackFeaturedPage } from '../helpers/api';
import { createSharedResource } from '../helpers/sharedResource';

const internalGetFeedbackPage = createSharedResource(
  'feedbackPage',
  (id) => {
    /** @type Array */
    const info = id.split('/');
    let [featured, shuffled] = [false, false];
    if (info.length !== 2) {
      const [_featured, _shuffled] = info.splice(0, info.length - 2);
      featured = _featured === "featured";
      shuffled = _shuffled === "shuffled";
    }
    const [page, pageSize] = info.map(Number);
    return featured
      ? feedbackFeaturedPage(page, pageSize, shuffled)
      : feedbackPage(page, pageSize);
  },
  null,
  { validFor: 1000 * 60 * 60 * 1 } // 1hour
);

const getFeedbackPage = (page, pageSize = 14, featured = false, random = false) => {
  if (featured) {
    return internalGetFeedbackPage(`featured/${random ? "shuffled" : "ordered"}/${page}/${pageSize}`);
  }
  return internalGetFeedbackPage(`${page}/${pageSize}`);
}
// const getFeedbackPage = (page, pageSize = 14) => feedbackPage(page, pageSize);

export default getFeedbackPage;
