import React, { useMemo } from 'react';
import StyledScore from './Score.style';
import { Stars } from './Stars';

const Score = ({ score, maxScore = 10, maxStars = 5, height, ...p }) => {
  const stars = useMemo(() => {
    const val = Number(score);
    if (isNaN(val)) return <>{score}</>;
    return (
      <Stars
        stars={(score / maxScore) * maxStars}
        maxStars={maxStars}
        height={height}
      />
    );
  }, [score]);
  return (
    <StyledScore {...p}>
      {stars}
      {score}
    </StyledScore>
  );
};
export default Score;
