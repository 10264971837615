import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { pxToRem } from '../theme/Theme';
import ScoreStyle from './Score.style';
import ReadMoreContainerStyle from './ReadMoreContainer.style';

export default styled.div`
  display: grid;
  ${({ wide }) =>
    wide
      ? css`
          grid-template-columns: 1fr auto;
          grid-template-rows: auto 1fr;
          grid-template-areas: 'header score' 'quote quote';
        `
      : css`
          grid-template-columns: 1fr;
          grid-template-rows: auto auto 1fr;
          grid-template-areas: 'header' 'score' 'quote';
        `}

  gap: 15px;
  background-color: ${({ theme, color = 'aliceBlue' }) => theme.colors[color]};
  border-radius: 4px;
  border: solid thin ${({ theme }) => theme.colors.quartz};
  padding: 30px;
  .header {
    grid-area: header;
    .client {
      margin-bottom: 5px;
    }
  }
  .score {
    grid-area: score;

    ${ScoreStyle} {
      background-color: initial;
      padding: 0;
      height: auto;
      width: fit-content;
      margin: 0 auto 0 0;
      font-size: ${pxToRem(16)};
      line-height: 120%;
      color: ${({ theme }) => theme.colors.trout};
      gap: 12.5px;
      .stars {
        gap: 5px;
      }
    }
  }
  .quote {
    grid-area: quote;
    padding-left: 39px;
    position: relative;
    .quote-icon {
      position: absolute;
      left: 0;
      top: 5px;
      color: ${({ theme }) => theme.colors.bahamaBlue};
    }
    .recommends {
      margin-top: 10px;
    }
  }
  ${ReadMoreContainerStyle} {
    --overlay-color: ${({ theme, color = 'aliceBlue' }) => theme.colors[color]};
    .read-more {
      & > a {
        position: absolute;
        bottom: 0;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
`;
